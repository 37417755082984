// Import Components
import React from 'react';

class SecretButton extends React.Component {

    constructor(props) {
        super();
    }

    render() {

        return (
           <div>
            Secret Button
           </div>
        );
    }
}

export default SecretButton;
