import React from "react";

import './CentisenpaiStyle.css';

class CentisenpaiBody extends React.Component {

    constructor(props) {
        super();
    }

    render() {

        return (
            <div>Centisenpai Body</div>
        );
    }
} export default CentisenpaiBody;