import './bone.css';

function Bone (props) {

    return (
        <div className="boneContainer">
            <div className="leftBone"></div>
            <div className="rightBone"></div>
        </div>
    )
} export default Bone;