import React from "react";

class BadMenu extends React.Component {

    constructor(props) {
        super();
    }

    render() {
        return(
            <div>
                bad menu
            </div>
        )
    }
} export default BadMenu;