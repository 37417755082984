import './rabbit.css';

function Rabbit(props) {

    return(
        <div className="rabbitContainer">
            <div className="rabbitLeftEar"></div>
            <div className="rabbitRightEar"></div>
            <div className="rabbitHead"></div>
            <div className="rabbitBody"></div>
            <div className="rabbitTail"></div>
        </div>
    );
} export default Rabbit;