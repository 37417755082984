//Import Components
import React from 'react';


class Bullet extends React.Component {

    constructor(props) {
        super();
    }

    render() {
        return (
            <div className="bullet">
            </div>
        );
    }
}

export default Bullet; 